import React from 'react';
import { useState, useEffect } from 'react';

import './Footer.css';

import logo from './Images/logo.svg';
import logodark from '../Header/Images/logo-dark.svg';

import down from './Images/down.svg';
import themelight from './Images/theme-light.svg';
import asklight from './Images/ask-light.svg';

const Footer: React.FC = () => {

    const [theme, setTheme] = useState<'light' | 'dark'>('light');

    useEffect(() => {
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme) {
            setTheme(savedTheme as 'light' | 'dark');
            document.body.className = savedTheme;
        }
    }, []);

    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        setTheme(newTheme);
        localStorage.setItem('theme', newTheme);
        document.body.className = newTheme;
    };

    return (
        <div className="footer">
            <div className="footer-container">
            <div className="container-logotype">
            <img src={theme === 'dark' ? logodark : logo} alt="" />
            </div>
            <div className="container-links">
                <div className="container-links__title">
                    Tools
                </div>
                <div className="container-links__links">
                    <div className="container-links__links-item">Trade/Swap</div>
                    <div className="container-links__links-item">Pools</div>
                    <div className="container-links__links-item">Stake</div>
                </div>
            </div>
            <div className="container-links">
                <div className="container-links__title">
                    Rebalancer
                </div>
                <div className="container-links__links">
                    <div className="container-links__links-item">About</div>
                    <div className="container-links__links-item">Blog</div>
                    <div className="container-links__links-item">Whitepaper</div>
                    <div className="container-links__links-item">Privacy policy</div>
                </div>
            </div>
            <div className="container-links">
                <div className="container-links__title">
                    Developers
                </div>
                <div className="container-links__links">
                    <div className="container-links__links-item">Documentation</div>
                    <div className="container-links__links-item">Github</div>
                </div>
            </div>
            <div className="container-links">
                <div className="container-links__title">
                    Join our community
                </div>
                <div className="container-links__links-com">
                    <div className="container-links__links-item-com">RU</div>
                    <div className="container-links__links-item-com">EN</div>
                    <div className="container-links__links-item-com">X</div>
                </div>
            </div>
            </div>
            <div className="footer-container-a">
            <div className="container-links-a">
            <div className="container-links">
                <div className="container-links__title">
                    Tools
                </div>
                <div className="container-links__links">
                    <img src={down} alt="" />
                </div>
            </div>
            <div className="container-links">
                <div className="container-links__title">
                    Rebalancer
                </div>
                <div className="container-links__links">
                    <img src={down} alt="" />
                </div>
            </div>
            <div className="container-links">
                <div className="container-links__title">
                    Developers
                </div>
                <div className="container-links__links">
                    <img src={down} alt="" />
                </div>
            </div>
            </div>
            <div className="container-other-a">
                <div className="container-other-a__item" onClick={toggleTheme}>
                    <img src={themelight} alt="" />
                </div>
                <div className="container-other-a__item">
                    RU
                </div>
                <div className="container-other-a__item">
                    <img src={asklight} alt="" />
                </div>
            </div>
            </div>
        </div>
    );
};

export default Footer;
