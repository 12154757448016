import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import Home from './pages/Home/Home';
import AddLiquidity from './pages/AddLiquidity/AddLiquidity';
import Pools from './pages/Pools/Pools';

import './App.css';

const App: React.FC = () => {
  return (
    <TonConnectUIProvider manifestUrl="https://ton.vote/tonconnect-manifest.json  ">
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/add-liquidity" element={<AddLiquidity />} />
        <Route path="/pools" element={<Pools />} />
      </Routes>
    </Router>
    </TonConnectUIProvider>
  );
};

export default App;
