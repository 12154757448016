import React, { useState } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Modal from './Modal';

import './Home.css';

import usdt from './Images/usdt.png';
import eth from './Images/eth.png';

import down from './Images/down.svg';
import slippage from './Images/slippage.svg';
import reload from './Images/reload.svg';

import downblue from './Images/down-blue.svg';

const Home: React.FC = () => {
    const [isSlippageVisible, setSlippageVisible] = useState(false);
    const [isModalVisible, setModalVisible] = useState(false);
    const [selectedSlippage, setSelectedSlippage] = useState('Auto');
    const [customSlippage, setCustomSlippage] = useState('');
    const [isSwapped, setIsSwapped] = useState(false);

    const toggleSlippageVisibility = () => {
        setSlippageVisible(!isSlippageVisible);
    };

    const toggleModalVisibility = () => {
        setModalVisible(!isModalVisible);
    };

    const handleSlippageSelect = (value: string) => {
        setSelectedSlippage(value);
        if (value !== 'Кастомный') {
            setCustomSlippage('');
        }
    };

    const handleCustomInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCustomSlippage(e.target.value);
        setSelectedSlippage(e.target.value);
    };

    const toggleSwap = () => {
        setIsSwapped(!isSwapped);
    };

    return (
        <div className="wrapper">
            <Header />
            <div className="container">
                <div className="container-window">
                    <div className="container-window__header">
                        <div className="container-window__header-start">
                            <div className="container-window__header-start__item">Swap</div>
                            <div className="container-window__header-start__item gray">Limit</div>
                        </div>
                        <div className="container-window__header-end">
                            <img src={reload} alt="" />
                        </div>
                    </div>
                    <div className="container-window__main">
                        <div className="forcircle">
                            <div className="container-window__main-container">
                                <div className="container-window__main-container__header">
                                    <div className="text">Вы продаёте</div>
                                    <div className="text">Баланс: {isSwapped ? '0' : '0.0293'}</div>
                                </div>
                                <div className="container-window__main-container__main">
                                    <div className="value" onClick={toggleModalVisibility}>
                                        <img src={isSwapped ? eth : usdt} alt="" />
                                        <div className="text-c">{isSwapped ? 'ETH' : 'USDT'}</div>
                                        <img src={down} alt="" className='downselect'/>
                                    </div>
                                    <div className="value">
                                        <input type='number' placeholder='0.00' className="text-c"></input>
                                    </div>
                                </div>
                                <div className="container-window__main-container__footer">
                                    <div className="text">{isSwapped ? 'Ether' : 'Tether USDT'}</div>
                                    <div className="text">{isSwapped ? '~ $4 988.08 (- 0,16%)' : '~ $4 916.12'}</div>
                                </div>
                            </div>
                            <div className="container-window__main-container border">
                                <div className="container-window__main-container__header">
                                    <div className="text">Вы получаете</div>
                                    <div className="text">Баланс: {isSwapped ? '0.0293' : '0'}</div>
                                </div>
                                <div className="container-window__main-container__main">
                                    <div className="value" onClick={toggleModalVisibility}>
                                        <img src={isSwapped ? usdt : eth} alt="" />
                                        <div className="text-c">{isSwapped ? 'USDT' : 'ETH'}</div>
                                        <img src={down} alt="" />
                                    </div>
                                    <div className="value">
                                        <input type='number' placeholder='0.00' className="text-c"></input>
                                    </div>
                                </div>
                                <div className="container-window__main-container__footer">
                                    <div className="text">{isSwapped ? 'Tether USDT' : 'Ether'}</div>
                                    <div className="text">{isSwapped ? '~ $4 916.12' : '~ $4 988.08 (- 0,16%)'}</div>
                                </div>
                            </div>
                            <div className="container-window__circle" onClick={toggleSwap}>
                                <img src={downblue} alt="" />
                            </div>
                        </div>
                        <div className="slippage">
                            <div className="container-slippage-container__start">
                                <img src={slippage} alt="" />
                                <div className="text">
                                    Проскальзование
                                </div>
                            </div>
                            <div className="container-slippage-container__end" onClick={toggleSlippageVisibility}>
                            <div className="text-button">{selectedSlippage}</div>
                            <img src={down} alt="" />
                        </div>
                        </div>
                        {isSlippageVisible && (
                            <div className="slippage-assets">
                                <div className="slippage-options">
                                {['Auto', '0,1%', '0,5%', '1%'].map(option => (
                                    <div 
                                        key={option} 
                                        className={`option ${selectedSlippage === option ? 'active-option' : ''}`} 
                                        onClick={() => handleSlippageSelect(option)}
                                    >
                                        {option}
                                    </div>
                                ))}
                                <input 
                                    type="number" 
                                    placeholder="Кастомный" 
                                    value={customSlippage} 
                                    onChange={handleCustomInputChange} 
                                    className="custom-slippage-input" 
                                    onClick={() => handleSlippageSelect('Кастомный')} 
                                />
                            </div>
                                <div className="slippage-details">
                                    <div className="detail">
                                        <span>Влияние на цену</span>
                                        <span className="detail-value">≈ +0,05%</span>
                                    </div>
                                    <div className="detail">
                                        <span>Мин. к получению</span>
                                        <span className="detail-value">≈ 3,58 TON</span>
                                    </div>
                                    <div className="detail">
                                        <span>Проскальзование</span>
                                        <span className="detail-value">5%</span>
                                    </div>
                                    <div className="detail">
                                        <span>Комиссия блокчейна</span>
                                        <span className="detail-value">≈ 0,18 TON</span>
                                    </div>
                                    <div className="detail">
                                        <span>Маршрут</span>
                                        <span className="detail-value">STON → TON</span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="container-window__footer">
                        <div className="container-window__footer-button">
                            <div className="button">
                                Обменять
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Modal isVisible={isModalVisible} onClose={toggleModalVisibility} />
        </div>
    );
};

export default Home;