import React, { useState } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import AssetSelector from './AssetSelector';

import './AddLiquidity.css';

import reload from './Images/reload.svg';
import filter from './Images/filter.svg';
import usdt from './Images/usdt.png';
import down from './Images/down.svg';
import downblue from './Images/down-blue.svg';

const AddLiquidity: React.FC = () => {
    const [showAssetSelector, setShowAssetSelector] = useState(false);
    const [selectedAssetOne, setSelectedAssetOne] = useState('USDT');
    const [selectedAssetImageOne, setSelectedAssetImageOne] = useState(usdt);
    const [selectedAssetTwo, setSelectedAssetTwo] = useState('Выбрать');
    const [selectedAssetImageTwo, setSelectedAssetImageTwo] = useState('');
    const [activeValue, setActiveValue] = useState<'one' | 'two' | null>(null);
    const [isSwapped, setIsSwapped] = useState(false);

    const handleAssetSelect = (value: 'one' | 'two') => {
        setActiveValue(value);
        setShowAssetSelector(true);
    };

    const handleAssetSelectorClose = (selectedToken?: { symbol: string, image: string }) => {
        setShowAssetSelector(false);
        if (selectedToken && activeValue) {
            if (activeValue === 'one') {
                setSelectedAssetOne(selectedToken.symbol);
                setSelectedAssetImageOne(selectedToken.image);
            } else if (activeValue === 'two') {
                setSelectedAssetTwo(selectedToken.symbol);
                setSelectedAssetImageTwo(selectedToken.image);
            }
        }
    };

    const toggleSwap = () => {
        setIsSwapped(!isSwapped);
    };

    return (
        <div className="wrapper">
            <Header />
            <div className="container">
            {showAssetSelector ? (
                <AssetSelector onClose={handleAssetSelectorClose} />
            ) : (
                <div className="container-window">
                <div className="container-window__header">
                    <div className="container-window__header-start">
                    <div className="container-window__header-start__item">Добавить ликвидность</div>
                    </div>
                    <div className="container-window__header-end">
                    <img src={reload} alt="" />
                    <img src={filter} alt="" />
                    </div>
                </div>
                <div className="container-window__main">
                    <div className="forcircle">
                    <div className="container-window__main-container">
                        <div className="container-window__main-container__header">
                        <div className="text">Актив 1</div>
                        <div className="text">Баланс: 0.0293</div>
                        </div>
                        <div className="container-window__main-container__main">
                        <div className="value one" onClick={() => handleAssetSelect('one')}>
                            <img src={isSwapped ? selectedAssetImageTwo : selectedAssetImageOne} alt="" />
                            <div className="text-c">{isSwapped ? selectedAssetTwo : selectedAssetOne}</div>
                            <img src={down} alt="" className='downselect'/>
                        </div>
                        <div className="value">
                            <input type='number' placeholder='0.00' className="text-c"></input>
                        </div>
                        </div>
                        <div className="container-window__main-container__footer">
                        <div className="text"></div>
                        <div className="text">$0</div>
                        </div>
                    </div>
                    <div className="container-window__circle" onClick={toggleSwap}>
                        <img src={downblue} alt="" />
                    </div>
                    <div className="container-window__main-container">
                        <div className="container-window__main-container__header">
                        <div className="text">Актив 2</div>
                        <div className="text">Баланс: 0</div>
                        </div>
                        <div className="container-window__main-container__main">
                        <div className="value two" onClick={() => handleAssetSelect('two')}>
                            {isSwapped ? <img src={selectedAssetImageOne} alt="" /> : <img src={selectedAssetImageTwo} alt="" />}
                            <div className="text-c">{isSwapped ? selectedAssetOne : selectedAssetTwo}</div>
                            <img src={down} alt="" />
                        </div>
                        <div className="value">
                            <input type='number' placeholder='0.00' className="text-c"></input>
                        </div>
                        </div>
                        <div className="container-window__main-container__footer">
                        <div className="text"></div>
                        <div className="text">$0</div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="container-window__footer">
                    <div className="container-window__footer-button">
                    <div className="button">
                        Введите сумму
                    </div>
                    </div>
                </div>
                </div>
            )}
            </div>
            <Footer />
        </div>
    );
};

export default AddLiquidity;