import React from 'react';

import close from './Images/close.svg';
import assettest from './Images/assettest.png';
import notify from './Images/notify.svg';

interface AssetSelectorProps {
  onClose: (selectedToken?: { symbol: string, image: string }) => void;
}

const AssetSelector: React.FC<AssetSelectorProps> = ({ onClose }) => {
  const handleImport = () => {
    const selectedToken = {
      symbol: 'TTAX',
      image: assettest
    };
    onClose(selectedToken);
  };

  return (
    <div className="container-window">
    <div className="container-window__header">
        <div className="container-window__header-start">
        <div className="container-window__header-start__item">Import a coin</div>
        </div>
        <div className="container-window__header-end">
          <img src={close} alt="" onClick={() => onClose()} />
        </div>
    </div>
    <div className="container-window__search">
        <input type="text"  placeholder='Поиск по названию или адресу'/>
    </div>
    <div className="container-window__main">
        <div className="container-window__main-token">
          <div className="container-window__main-token__start">
          <div className="token-img">
            <img src={assettest} alt="" />
          </div>
          <div className="token-container">
            <div className="token-container__symb">
              TTAX
            </div>
            <div className="token-container__name">TONTAX</div>
          </div>
          </div>
          <div className="container-window__main-token__end">
          <div className="token-container contract">
            <div className="token-container__symb">
              EQCU...
            </div>
            <div className="token-container__name-c">View on Tonscan</div>
          </div>
          </div>
        </div>
        <div className="container-window__main-notify">
          <div className="notify-img">
            <img src={notify} alt="" />
          </div>
          <div className="notify-text">
          This coin isn’t whitelested. anyone can create coins on ton, including fakes of existing ones. make sure you’re aware of risks associated with non-whitelisted coins
          </div>
        </div>
    </div>
    <div className="container-window__footer">
        <div className="container-window__footer-button">
        <div className="button" onClick={handleImport}>
            Import
        </div>
        </div>
    </div>
    </div>
  );
};

export default AssetSelector;